/* Navbar */
.nav-link {
    margin: 0 16px;
    padding: 8px 0;
    position: relative;
    place-items: center;
    height: 100%;
    display: grid;
}

.nav-link:hover {
    color: var(--theme-orange);
}

.nav-link::after {
    height: 0;
    background-color: var(--theme-orange);
    content: '';
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
}

.nav-link:hover::after {
    height: 4px;
    transition: height 250ms ease-in-out;
}

.nav-dropdown {
    color: white;
    position: absolute;
    width: max-content;
    z-index: 200;
    display: none !important;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    background-color: rgba(49, 50, 38, .8);
}

.nav-dropdown-item {
    position: relative;
    padding: 12px;
    border-bottom: 1px solid #b3b3b3;
    transition: all 0.3s ease-in-out;
}

.nav-dropdown-item:hover {
    background-color: hsla(0, 0%, 100%, .799);
    color: black;
}

.services:hover .nav-dropdown,
.admin-link:hover .nav-dropdown,
.blogs:hover .nav-dropdown {
    display: block !important;
}

/* hero section classes */
.hero-section {
    background: url('/public/assets/heroBg.png') no-repeat center;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .hero-section {
        background: var(--theme-orange) url('/public/assets/mobileBanner.jpg') no-repeat top;
        background-size: contain;
        align-content: end;
        padding: 80px 1rem 1rem 1rem !important;
        text-align: center;
    }

    .hero-heading {
        font-size: 22px !important;
        text-align: center;
        padding-bottom: 6px !important;
    }
}

/* home page classes*/
.what-we-do-section {
    background-image: url('/public/assets/whatwedomatrix.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.single-service:nth-child(odd) {
    justify-self: start;
    left: 0;
}

.single-service:nth-child(even) {
    justify-self: end;
    right: 0;
}

.ot-service:nth-child(1),
.ct-service:nth-child(1),
.analytic-benefit:nth-child(1),
.single-service:nth-child(1) {
    animation: ltr 2s ease-out 0s forwards;
}

.ot-service:nth-child(2),
.ct-service:nth-child(2),
.analytic-benefit:nth-child(2),
.single-service:nth-child(2) {
    animation: rtl 2s ease-out 2s forwards;
}

.ot-service:nth-child(3),
.ct-service:nth-child(3),
.analytic-benefit:nth-child(3),
.single-service:nth-child(3) {
    animation: ltr 2s ease-out 4s forwards;
}

.ot-service:nth-child(4),
.ct-service:nth-child(4),
.analytic-benefit:nth-child(4),
.single-service:nth-child(4) {
    animation: rtl 2s ease-out 6s forwards;
}

.why-choose-us-section {
    background: linear-gradient(0.74deg, #7C7C7C -28.58%, rgba(185, 184, 184, 0) 26.26%);
}

/* Services */
.sp-benefit {
    background: #EFEDEC url('/public/assets/services/servicesPageBenefitsBg.svg') no-repeat center;
    background-size: cover;
}

/* Operation Transformation */
.ot-hero-section {
    background: url('/public/assets/services/OT/main.jpg') no-repeat center;
    background-size: cover;
}

.ot-benefits-container div:nth-child(2) {
    margin-top: 6rem;
}

.service-circle {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

/* Product-Development */
.pd-hero-section {
    background: url('/public/assets/services/PD/HeroBg.jpg') no-repeat center;
    background-size: cover;
}

/* Analytics */
.analytics-hero-section .hero {
    background: url('/public/assets/services/Analytics/AnalyticsHeroBg.jpg') no-repeat center;
    background-size: cover;
}

.analytics-our-services {
    background: url('/public/assets/services/Analytics/ourServicesBg.svg') no-repeat center;
    background-size: cover;
}

/* Cloud Transformation */
.ct-hero-section {
    background: url('/public/assets/services/CT/CTHero.svg') no-repeat center;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .ct-hero-section {
        background: url('/public/assets/services/CT/CTMobile.svg') no-repeat top;
        background-size: cover;
        text-align: center;
    }

    .ct-hero-section .hero-left {
        align-self: end;
    }

    .ct-hero-section .hero-heading {
        text-align: center;
    }

    .ct-hero-section h3 {
        display: none;
    }
}

/* contact us */
.contact-hero-section {
    background: url('/public/assets/ContactHero.jpg') no-repeat center;
    background-size: cover;
}

.contact-form-container label {
    font-weight: 500;
}

.contact-form-container span {
    color: var(--theme-orange);
    margin-inline: 4px;
}

.error-message {
    margin-block: -18px 8px;
    font-weight: 400;
    color: var(--theme-orange);
}

.contact-info {
    background: #EDEDED url('/public/assets/contactright.png') no-repeat center;
    background-size: cover;
}

.contact-input {
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    background-color: transparent;
    padding: 6px 8px;
    margin: 4px 0 20px 0;
    width: 90%;
}

/* contact-checkbox */
.cb {
    width: initial;
}

.contact-hero-section .get-in-touch-btn {
    display: none;
}

/* Privacy Policy */
.privacy-content p strong {
    color: var(--theme-orange);
}

/* Footer */
.footer-section {
    background: linear-gradient(181.23deg, rgba(232, 229, 229, 0) 27.86%, #98999B 93.01%);
}

.left-section {
    background: #5F5F60 url("/public/assets/FooterWave.png") no-repeat center;
    background-size: cover;
}

.get-in-touch-form input,
textarea {
    width: 100%;
    margin-block: 8px;
    border-radius: 8px;
    padding: 1px 8px;
    color: black !important;
    resize: none !important;
}

.send-btn {
    background: #272727 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
}

/* create and edit blog css */
.blog-content::-webkit-scrollbar {
    width: 0px;
}

.blog-form-error {
    color: #ff0000;
}