@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --theme-orange: #E4842D;
    --theme-blue: #1F2960;
    --theme-bg: #D9D9D9;
    --theme-gray: #D0D0D0;
    --theme-ohover: #ff7900;
    --theme-bhover: #010b42;
}

* {
    font-family: 'Roboto Serif', serif;
}

.btn {
    margin: .25rem .125rem;
    padding: 0.375rem 0.75rem;
    border-radius: 8px;
}

.btn-info {
    color: black;
    background-color: #0dcaf0;
}

.btn-info:hover {
    background-color: #31d2f2;
}

.btn-danger {
    color: white;
    background-color: #dc3545;
}

.btn-danger:hover {
    background-color: #bb2d3b;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden scroll;
}

/* Global classes */
.hero-heading {
    font-size: 28px;
    font-weight: 500;
}

.get-in-touch-btn {
    background: var(--theme-orange);
    color: white;
    font-size: 16px;
    font-weight: 400;
}

.section-heading {
    font-size: 22px;
    font-weight: 500;
}

.crumb {
    color: var(--theme-blue);
    padding-block: 8px;
    width: 210px;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    display: inline-block;
    border: 1px solid var(--theme-orange);
}

.sub-heading {
    font-size: 16px;
    font-weight: 600;
}

.sub-heading-content {
    font-weight: 400;
    font-size: 14px;
}

/* animations */
@keyframes ltr {
    0% {
        left: -100px;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes rtl {
    0% {
        left: 100px;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        top: -50px;
        opacity: 0;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeInDown {
    position: relative;
    animation: fadeInDown 2s ease-in-out;
}

.fade {
    opacity: 0;
    animation: fade 3.5s ease-in-out forwards;
}